<template>
  <div class="about-page w-full h-screen">
    <popup-charge :want-to-make-offer="charge" v-if="charge"/>
    <div class="containerStyle mr-auto ml-auto">
      <div class="md:flex items-center">
        <div class="md:w-2/5 flex items-center cursor-pointer" @click="retour">
          <svg-icon
            name="back"
            class="md:h-16 h-12 mr-4"
            original
          />
          <div class="titre">Mes devis</div>
        </div>

        <div class="md:w-3/5 w-full md:flex justify-end md:mt-0 mt-4">
          <div class="champ flex items-center pl-4 pr-4 md:w-1/2">
            <svg-icon
              name="search"
              class="md:h-6 h-4 mr-4"
              original
            />
            <input type="text" v-model="search" class="w-4/5 search" placeholder="Rechercher">
          </div>
        </div>
      </div>

      <div class="mt-5 liste">
        <devisListe v-for="(item, index) in donnee" :key="index" :item="item" class="mb-5" @delete="retourDelete" @oga="openDetail"/>
      </div>
    </div>
  </div>
</template>

<script>
import devisListe from '@/components/cedro/devis/devisListe'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import popupCharge from '@/components/popup/popupCharge'

export default {
  name: 'index',
  components: {
    devisListe,
    popupCharge
  },
  data () {
    return {
      charge: false,
      filtres: 'Toutes mes primes',
      allFiltres: ['Toutes mes primes', 'Actives', 'Expirées'],
      selected: null,
      activeOption: false,
      search: null,
      allPrime: [
        {
          name: 'Hyundai | BV 1234 RB',
          Expire: 'Expire dans 12 jours',
          amount: 47696,
          isActive: true,
          type: 'Auto'
        },
        {
          name: 'Dayang  | BV 1234 RB',
          Expire: 'Expire dans 12 jours',
          amount: 14696,
          isActive: false,
          type: 'Moto'
        },
        {
          name: 'Hyundai | BV 1234 RB',
          Expire: 'Expire dans 12 jours',
          amount: 47696,
          isActive: true,
          type: 'Auto'
        },
        {
          name: 'M/ALITANOU | COTONOU',
          Expire: 'Expire dans 12 jours',
          amount: 287696,
          isActive: false,
          type: 'Mrh'
        },
        {
          name: 'Paris  | 6 JOURS',
          Expire: 'Expire dans 12 jours',
          amount: 12696,
          isActive: true,
          type: 'Voyage'
        }
      ]
    }
  },

  computed: {
    donnee: function () {
      let retour = this.allPrime
      if (this.filtres === 'Toutes mes primes') {
        retour = this.allPrime
      }
      if (this.filtres === 'Actives') {
        retour = retour.filter((item) => {
          if (item.isActive) {
            return item
          }
        })
      }

      if (this.filtres === 'Expirées') {
        retour = retour.filter((item) => {
          if (!item.isActive) {
            return item
          }
        })
      }

      if (this.search !== null) {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        retour = retour.filter((item) => {
          if (item.type === 'Moto') {
            if ((item.autre.plaque.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.marque.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          }

          if (item.type === 'Auto') {
            if ((item.plaque.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.marque.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.model.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          }

          if (item.type === 'Mrh') {
            if ((item.model.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.occupation.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          }

          if (item.type === 'Voyage') {
            if ((item.destination.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.pays.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          }
        })
      }
      return retour.reverse()
    }
  },

  mounted () {
    this.allPrime = this.$store.getters.allDevis
  },

  methods: {
    goTo (index) {
      this.$router.push('/' + index)
    },

    retour () {
      this.$router.push('/home')
    },

    retourDelete (answer) {
      this.$store.dispatch('deleteDevis', answer.id)
      this.saveDevis()
    },

    allDevisSave () {
      http.get(apiroutes.baseURL + apiroutes.getDevis)
        .then(response => {
          this.charge = false
          if (response.data.allDevis !== undefined) {
            this.allPrime = response.data.allDevis
            this.$store.dispatch('updateAllDevis', response.data.allDevis)
          }
        })
        .catch(error => {
          this.charge = false
          console.log('erreur')
          console.log(error)
        })
    },

    saveDevis () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.saveDevis, {
        estimate: {
          allDevis: this.$store.getters.allDevis
        }
      })
        .then(response => {
          this.allDevisSave()
        })
        .catch(error => {
          this.charge = false
          console.log('erreur')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.baniere{
  width: 100%;
  border-radius: 20px;
  height: 159px;
  background: #0269AD;
  color: #FFFFFF;
  font-family: $rubik;
}
.defil{
  position: absolute;
  width: 25%;
}
.champ{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: $rubik;
  color: #909090;
  height: 70px;
}

.enonce{
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
}
.reponse{
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
}

.about-page{
  width: 100%;
  height: 100%;
  font-family: $font-default;
  padding-bottom: 100px;
  min-height: 100vh;
}
.details{
  width: 66%;
}
.containerStyle{
  width: 71%;
}
.titre{
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
  font-family: $rubik
}
.button{
  height: 71px;
  background: #FFFFFF;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.04em;
  color: #000000;
  font-family: $font-default;
  border: none;
}
.search{
  font-family: $rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #909090;
}
.svg-fill{
  fill: white;
}
@media screen and (max-width: 700px){
  .containerStyle {
    width: 90%;
    height: 100%;
  }
  .baniere {
    height: 182px;
    border-radius: 20px;
  }
  .titre {
    font-size: 26px;
  }
  .button {
    height: 49px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 14px;
  }
  .champ {
    height: 54px;
  }
  input{
    font-size: 18px;
  }
}
</style>
