<template>
  <div class="w-full h-full all md:flex p-5 pl-6 pr-6 cursor-pointer">
    <img src="../../../assets/icons/vehicule.svg" alt="" @click="selected" class="h-12 mr-4 mt-2" v-if="item.type === 'Auto'">
    <img src="../../../assets/icons/moto.svg" alt="" @click="selected" class="h-12 mr-4 mt-2" v-if="item.type === 'Moto'">
    <img src="../../../assets/icons/voyage.svg" alt="" @click="selected" class="h-12 mr-4 mt-2" v-if="item.type === 'Voyage'">
    <img src="../../../assets/icons/mrh.svg" alt="" @click="selected" class="h-12 mr-4 mt-2" v-if="item.type === 'Mrh'">

    <div class="md:w-2/5" @click="selected">
      <div class="nom">
        <span v-if="item.type === 'Auto'">{{item.marque}} | {{item.plaque}}</span>
        <span v-if="item.type === 'Moto'">{{item.marque}} | {{item.model}}</span>
        <span v-if="item.type === 'Voyage'">{{item.pays}} | {{item.destination}}</span>
        <span v-if="item.type === 'Mrh'">{{item.model.model}} | {{item.model.adresse}}</span>
      </div>
      <div class="expire mt-2"> Expire le {{ conversion(item.createdAt) }}</div>
    </div>

    <div class="md:w-3/5 md:flex">
      <div class="nom md:w-2/3 md:text-center" @click="selected">
        {{item.devis.computed}} Fr
      </div>

      <div class="md:flex md:w-1/3 justify-end items-center">
        <div class="button flex" @click="selected">
            <div class="mr-2">
              Finaliser
            </div>
          <svg-icon
            name="chevron-right"
            class="h-4 "
            original
            color="white"
          />
        </div>
        <div class="crois ml-3" @click="deleteDevis"> X </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
    }
  },

  methods: {
    selected () {
      this.$store.dispatch('position', this.item.position)
      this.$store.dispatch('saveFirstDevis', this.item)
      if (this.item.type === 'Auto') {
        this.$router.push('/automobile')
      }
      if (this.item.type === 'Moto') {
        this.$router.push('/moto')
      }
      if (this.item.type === 'Voyage') {
        this.$router.push('/voyage')
      }
      if (this.item.type === 'Mrh') {
        this.$router.push('/mrh')
      }
    },

    deleteDevis () {
      this.$store.dispatch('deleteDevis', this.item.id)
      this.$emit('delete', this.item)
    },

    conversion (date) {
      const temps = 15 * (1000 * 3600 * 24)
      const count = date + temps
      return new Date(count).toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .nom{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #000000;
  }
div{
  font-family: 'Mulish';
}
.expire{
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #909090;
}
.crois{
  display: none;
  color: red;
  font-size: 22px;
  font-weight: bold;
}
.all{
  background: #FFFFFF;
  border-radius: 20px;
}
.all:hover{
  background: linear-gradient(180deg, #e1f2fc 0%, rgba(255, 255, 255, 0) 100%);
  .crois{
    display: block;
  }
}
.sous{
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
}

.resultat{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #909090;
  font-family: $popins;
}

.enonce{
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  font-family: $popins;
}

.button{
  height: 50px;
  background: #0269AD;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  border: none;
}
.expire-bout{
  background-color: #FF787F;
}
.svg-fill{
  fill: #FBD4D6;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .fullContain{
    width: 70%;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .fullContain{
    width: 90%;
  }
  .titre{
    font-size: 38px;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 700px){
  .button{
    height: 40.64px;
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
  }
  .fullContain{
    width: 90%;
  }
  img{
    height: 2rem;
  }
  .titre{
    font-size: 28px;
    margin: 0px !important;
  }

  .nom{
    width: 100%;
    span{
      font-size: 18px;
    }
  }
  .expire{
    width: 100%;
    font-size: 10px;
  }
  .all{
    height: auto;
    padding: 15px 20px !important;
  }
}

</style>
